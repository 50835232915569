import { template as template_7f440a19234446768e740f088603e23b } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_7f440a19234446768e740f088603e23b(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
