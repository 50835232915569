import { template as template_9acb9aa10d394a1f9ff3e83b5f6b7ef4 } from "@ember/template-compiler";
const FKLabel = template_9acb9aa10d394a1f9ff3e83b5f6b7ef4(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
